
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import {
  apiGameQuarkShortplayLists,
  apiGameQuarkShortplayDelete, apiGameQuarkShortplayStatus
} from '@/api/setting'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'


@Component({
  components: {
    LsDialog,
    LsPagination,
    ExportData
  }
})
export default class GameLevelLists extends Vue{
  /** S Data **/
      // 分页请求
  pager: RequestPaging = new RequestPaging()
  /** E Data **/


  tableData = []


  pickerOptions = {
    shortcuts: [
      {
        text: '最近一周',
        onClick(picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近一个月',
        onClick(picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近三个月',
        onClick(picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          picker.$emit('pick', [start, end])
        }
      }
    ]
  }


  splitTime() {
    if (this.tableData != null) {
      this.form.start_time = this.tableData[0]
      this.form.end_time = this.tableData[1]
    }
  }


  /** S Methods **/

  form = {
    title: '',
    is_die: '',
    is_show: '',
    end_time: '',
    start_time: '',
  }
  // 获取列表
  getList(page?: number) {
    page && (this.pager.page = page)
    this.pager
        .request({
          callback: apiGameQuarkShortplayLists,
          params: {
            aid: 0,
            ...this.form
          }
        })
        .catch(() => {
          this.$message.error('数据请求失败，刷新重载!')
        })
  }

  // 重置
  onReset() {
    this.form = {
      title: '',
      is_die: '',
      is_show: '',
      start_time: '',
      end_time: '',
    }

    this.tableData = []
    this.getList()
  }
  // 新增
  onAdd() {
    this.$router.push({
      path: '/game_search/quark_shortplay/edit',
      query: {
        mode: PageMode.ADD,
        aid: '0',
      }
    })
  }

  // 编辑
  onEdit(item: any) {
    this.$router.push({
      path: '/game_search/quark_shortplay/edit',
      query: {
        mode: PageMode.EDIT,
        id: item.id,
        aid: '0',
      }
    })
  }

  // 删除
  onDel(item: any) {
    apiGameQuarkShortplayDelete({
      id: item.id as number
    })
        .then(() => {
          this.getList()

        })
        .catch(() => {
          this.$message.error('删除失败')
        })
  }

  changeStatus(value: 0 | 1, row: any) {
    apiGameQuarkShortplayStatus({
      id: row.id,

    })
        .then(() => {
          this.getList()
        })
        .catch((err: any) => {
          console.log('err', err)
        })
  }

  async onTabClick(event: any) {
    const loading = this.$loading({
      lock: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.5)',
      customClass: 'switch-loading'
    })
    this.pager.page = 1;
    this.getList()
    loading.close()
  }

  /** E Methods **/
  /** S Life Cycle **/
  async created() {
    this.getList()
  }

  /** E Life Cycle **/
}
